
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
  }
  .listings-filter-modal .modal-content {
    color: #000; /* Make sure text is black or a dark color */
  }