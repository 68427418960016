/* ClusteredListings.css */

/* General Container */
.clustered-listings-container {
  padding: 20px;
}

/* Updated Listings Evaluator Description */
.listings-evaluator-description {
  background: #f3f4f6;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 0 150px 20px 150px; /* Increased left and right margins from 0 to 20px */
}

.listings-evaluator-description:hover {
  background: #f8f9fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.description-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-right: 30px; /* Space for the arrow */
}

.description-header h3 {
  margin: 0;
  color: #111827;
  font-weight: 600;
  font-size: 1.25rem;
  min-width: 150px;
}

.description-content {
  flex: 1;
  padding: 0 20px;
}

.description-content p {
  color: #374151;
  line-height: 1.6;
  margin: 0 0 8px 0;
  font-size: 1.05rem;
}

.see-more-info {
  font-style: italic;
  color: #6b7280;
  font-size: 0.95rem !important;
  margin-top: 8px !important;
}

.description-header .dropdown-arrow {
  color: #333333;
  font-size: 14px;
  transition: transform 0.3s ease;
  align-self: center;
}

.description-header .dropdown-arrow.expanded {
  transform: rotate(180deg);
}

.description-details {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e5e7eb;
  animation: fadeIn 0.3s ease-in-out;
}

.description-details p {
  color: #374151;
  line-height: 1.6;
  margin: 0 0 10px 0;
  font-size: 1.05rem;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Feature list styling - improved for wide screens */
.listings-features-list {
  margin: 12px 0 0 0;
  padding-left: 20px; /* Add left padding */
  padding-right: 20px; /* 
  list-style-type: none;
  max-width: 1200px; /* Limit the width to prevent excessive stretching */
  text-align: left; /* Explicitly set left alignment */
}

.listings-features-list li {
  color: #374151;
  line-height: 1.6;
  margin: 8px 0;
  font-size: 1.05rem;
  position: relative;
  display: block;
  padding-left: 20px; /* Space for the bullet */
  text-align: left; /* Ensure list items are left-aligned */
}

.listings-features-list li::before {
  content: "•";
  color: #333333;
  font-weight: bold;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

.listings-features-list li strong {
  color: #111827;
  font-weight: 600;
}

/* Clustering Controls Title */
.clustering-controls-title {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #4b5563;
  margin: 0 0 15px 0;
  text-align: center;
}

/* Container for Clustered Listings Buttons */
.clustered-listings-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to next line */
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 0 10px; /* Add some padding on the sides */
}

/* General Button Styling */
.clustered-listings-buttons button {
  margin: 5px 0; /* Add vertical margin for wrapped buttons */
  padding: 8px 15px;
  cursor: pointer;
  background-color: transparent;
  color: #009999;
  border: 2px solid #009999;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  min-width: 120px; /* Ensure buttons have a minimum width */
  flex: 0 1 auto; /* Allow buttons to shrink but not grow */
  white-space: nowrap; /* Prevent button text from wrapping */
  font-weight: 500;
}

/* Active Button State */
.clustered-listings-buttons button.active {
  background-color: #009999;
  color: white;
  border-color: #009999;
}

/* Optional: Hover State for Buttons */
.clustered-listings-buttons button:hover {
  background-color: #009999;
  color: white;
  border-color: #009999;
}

/* Button Separator */
.button-separator {
  width: 10px;
  margin: 0 5px;
}

/* Search Button */
.search-button {
  background-color: #fd9245 !important;
  color: white !important;
  border: none !important;
  margin-left: 20px !important;
}

.search-button:disabled, 
.search-button.loading {
  background-color: #e0e0e0 !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

/* Inactive Toggle Button */
.inactive-toggle-button {
  background-color: transparent !important;
  margin-left: 20px !important;
  color: #009999 !important;
  border: 2px solid #009999 !important;
}

.inactive-toggle-button:disabled,
.inactive-toggle-button.disabled {
  color: #cccccc !important;
  border-color: #cccccc !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

/* Marker Styling */
.marker {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Map and Cluster Summary Container */
.map-and-summary-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

/* Map Section */
.map-section {
  width: 40%;
  height: 700px;
  position: relative;
}

/* Mapbox specific styles */
.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

/* Ensure popups are visible */
.mapboxgl-popup {
  z-index: 1000;
  pointer-events: none !important; /* Ignore all mouse events */
}

.mapboxgl-popup-content {
  pointer-events: none !important; /* Ignore all mouse events */
}

/* Cluster Summary Section */
.cluster-summary-section {
  width: 60%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  overflow: auto;
  max-height: 700px;
}

/* Table Containers */
.listings-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px; /* Increased from 10px */
  background-color: #f9f9f9;
  margin-top: 20px;
  flex: 1 1 100%;
}

/* Add specific styles for the DataTable component */
.rdt_Table {
  padding-left: 16px; /* Add left padding to the table */
  padding-right: 16px;
}

.rdt_TableHead {
  padding-left: 8px; /* Add left padding to the header */
}

.rdt_TableBody {
  padding-left: 8px; /* Add left padding to the body */
}

/* Data Table Row Styling */
.data-table-row {
  border-bottom: 1px solid #e0e0e0; /* Light border between rows */
}

/* Data Table Header Styling */
.data-table-header {
  border-bottom: 2px solid #e0e0e0; /* Slightly thicker border for header */
  font-weight: bold;
}

/* Data Table Button Styling (if any) */
.data-table-button {
  padding: 5px 10px;
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 5px;
}

.property-type-button {
  padding: 5px 10px;
  border: none;
  background-color: #dedddd;
  border-radius: 5px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .clustered-listings-buttons {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    align-items: stretch; /* Make buttons full width */
  }

  .clustered-listings-buttons button {
    width: 100%; /* Full width buttons on mobile */
    margin: 5px 0;
  }

  .container {
    flex-direction: column;
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .map-container,
  .table-container {
    width: 100%;
  }

  .map {
    height: 800px;
  }

  .table-container {
    height: auto; /* Allow tables to expand naturally */
  }
}

.rdt_ExpanderRow {
  background-color: #f8f9fa !important;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
}

.chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 10px 0;
  padding: 15px;
}

.chart-wrapper {
  position: relative;
  height: 300px;
  width: 100%;
}

/* Legend Styles - Debug version */
.jobs-legend {
  position: absolute !important;
  bottom: 40px !important;
  left: 20px !important;
  background: white !important;
  padding: 10px !important;
  font-family: Arial, sans-serif !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  display: flex !important;
  align-items: center !important;
  z-index: 9999 !important;
  pointer-events: none !important;
}

.jobs-legend-gradient {
  width: 20px !important;
  height: 400px !important;
  background: linear-gradient(to bottom, #225ea8, #1d91c0, #41b6c4, #7fcdbb, #c7e9b4, #ffffcc) !important;
}

.jobs-legend-labels {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 400px !important;
  margin-left: 10px !important;
}

.jobs-legend-labels span {
  font-size: 12px !important;
  text-align: left !important;
  color: #333 !important;
}

.selected-marker {
  background-image: url('https://docs.mapbox.com/demos/custom-markers-gl-js/mapbox-icon.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

/* Responsive design for smaller screens */
@media (max-width: 1200px) {
  .map-and-summary-container {
    flex-direction: column;
  }

  .map-section,
  .cluster-summary-section {
    width: 100%;
  }
}

.selected-listings-charts {
  margin-top: 30px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.charts-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.chart-wrapper {
  flex: 1;
  min-width: 400px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

@media (max-width: 900px) {
  .charts-container {
    flex-direction: column;
  }
  
  .chart-wrapper {
    width: 100%;
  }
}

.selected-listings-summary {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.selected-listings-summary h3 {
  margin-bottom: 15px;
  color: #333;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.summary-table td {
  padding: 8px;
  border: 1px solid #eee;
}

.summary-table td:first-child {
  font-weight: bold;
  width: 150px;
  background-color: #f8f9fa;
}

.summary-table td:nth-child(3) {
  font-weight: bold;
  background-color: #f8f9fa;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider:before {
  transform: translateX(20px);
}

/* Visibility Dropdown Styles */
.visibility-dropdown-container {
  position: relative;
  margin-left: 20px;
}

.visibility-dropdown-button {
  background-color: #009999 !important;
  color: white !important;
  border: none !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.dropdown-arrow {
  font-size: 10px;
  transition: transform 0.3s ease;
}

.visibility-dropdown-button:hover .dropdown-arrow {
  transform: translateY(2px);
}

.visibility-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 220px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 5px;
}

.visibility-option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 4px;
}

.visibility-option:hover {
  background-color: #f5f5f5;
}

.visibility-option input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
  accent-color: #009999;
}

.visibility-option .disabled-text {
  color: #cccccc;
  cursor: not-allowed;
}

/* Close dropdown when clicking outside */
.visibility-dropdown-container:focus-within .visibility-dropdown-menu {
  display: block;
}