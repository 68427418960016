.login-container {
  max-width: 400px;
  margin: 50px auto 0 auto; /* Keeps the top margin as 50px and centers horizontally */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white !important; /* Revert to white background */
  overflow: hidden;
  height: 380px
}

  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form .form-control {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    background-color: #fff5eb !important; /* Light orange background for inputs */
  }
  
  .login-form .btn {
    width: 100%;
  }
  
  .message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
  
.sso-button-container {
  margin-bottom: 20px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dee2e6;
}

.divider span {
  padding: 0 10px;
  color: #6c757d;
  background: #fff;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.modal-dialog {
  margin-top: 10vh;
}

/* Add these new styles */
.btn-primary {
  background-color: #009999 !important; /* Teal */
  border-color: #009999 !important;
}

.btn-primary:hover {
  background-color: #008080 !important; /* Slightly darker teal for hover */
  border-color: #008080 !important;
}

.btn-link {
  color: #009999 !important; /* Teal */
}

.btn-link:hover {
  color: #008080 !important; /* Slightly darker teal */
}

a {
  color: #009999 !important; /* Teal */
}

a:hover {
  color: #008080 !important; /* Slightly darker teal */
}

/* Override autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff5eb inset !important;
    -webkit-text-fill-color: #000000 !important;
}

.form-control {
    background-color: #fff5eb !important;
}
  