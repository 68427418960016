.chatbox-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-toggle-button {
  width: min(220px, 90vw);
  min-width: auto;
  height: 50px;
  border-radius: 25px;
  background-color: #009999;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 17px;
  padding: 0 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  right: 0;
}

.chat-window {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: min(600px, 90vw);
  height: min(600px, 80vh);
  max-height: calc(100vh - 150px);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform-origin: bottom right;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chat-window.expanded {
  transform-origin: bottom right;
  width: 100vw;
  height: calc(100vh - 76px);
  position: fixed;
  top: 76px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
  animation: expandFromBottomRight 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes expandFromBottomRight {
  from {
    transform: scale(0.5);
    opacity: 0.8;
    right: 20px;
    bottom: 70px;
  }
  to {
    transform: scale(1);
    opacity: 1;
    right: 0;
    bottom: 0;
  }
}

.chat-window.expanded .chat-content {
  height: calc(100% - 120px);
}

.chat-window.expanded .messages-container {
  height: 100%;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: #009999;
  color: white;
  position: relative;
  height: 50px;
  box-sizing: border-box;
}

.window-controls {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-right: -2px;
}

.window-control-button {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 15px;
  min-width: 70px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window-control-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.close-button:hover {
  background: rgba(255, 0, 0, 0.6);
}

.new-chat-button {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 15px;
  min-width: 70px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.new-chat-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.chat-header h3 {
  margin: 0;
  font-size: 17px;
  color: white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  transition: width 0.3s ease;
  height: 100%;
}

.message {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 17px;
}

.message.user {
  background-color: #009999;
  color: white;
  align-self: flex-end;
}

.message.bot {
  background-color: #f0f0f0;
  color: black;
  align-self: flex-start;
  text-align: left;
}


.chat-input-form {
  height: 70px;
  box-sizing: border-box;
  display: flex;
  padding: 15px;
  gap: 10px;
  border-top: 1px solid #eee;
  background-color: white;
}

.chat-input-form input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 38px;
}

.chat-input-form button {
  padding: 8px 15px;
  background-color: #009999;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 38px;
}

.chat-input-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 5px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #90949c;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
}

/* Style markdown content */
.message.bot ul, 
.message.bot ol {
  padding-left: 20px;
  margin: 10px 0;
}

.message.bot p {
  margin: 8px 0;
}

.message.bot strong {
  font-weight: 600;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.history-button {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid white;
  background: transparent;
  color: white;
  cursor: pointer;
}

.history-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.history-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: white;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  z-index: 2;
}

.history-item {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.history-item:hover {
  background-color: #f5f5f5;
}

.history-item p {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-item small {
  color: #666;
  font-size: 12px;
}

.history-sidebar + .messages-container {
  margin-left: 250px;
  margin-right: 0;
}

.welcome-message {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  gap: 15px;
  padding: 15px;
  justify-items: center;
  align-items: start;
}

.welcome-intro {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #333;
}

.expand-hint {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  font-weight: normal;
}

.suggested-question {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #333;
  font-size: 15px;
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  width: 100%;
  max-width: 280px;
  min-height: 50px;
  white-space: normal;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  height: auto;
}

.suggested-question:hover {
  background-color: #f8f8f8;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.expand-button {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid white;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 18px;
}

.expand-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.chat-content {
  display: flex;
  flex: 1;
  position: relative;
  height: calc(100% - 120px);
  overflow: hidden;
}

.messages-container.with-history {
  width: calc(100% - 250px);
  margin-left: 250px;
  margin-right: 0;
}

/* Add specific styles for square buttons */
.window-control-button.square-button {
  min-width: 32px;
  width: 32px;
  padding: 0;
  font-size: 17px;
}

.expand-hint {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  font-weight: normal;
}

/* When the window is expanded, show questions in a grid */
.chat-window.expanded .welcome-message {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-items: center;
}

.chat-window.expanded .welcome-message p {
  grid-column: 1 / -1; /* Make paragraphs span full width */
}

.chat-window.expanded .suggested-question {
  width: 280px;
  min-height: 60px;
}

.left-controls {
  display: flex;
  gap: 6px;
  margin-right: auto;
  flex-direction: row;
  align-items: center;
}

/* Adjust history sidebar for mobile */
@media (max-width: 768px) {
  .messages-container.with-history {
    width: 100%;
    margin-left: 0;
  }
  
  .history-sidebar {
    left: 0;
    right: auto;
  }
  
  .chat-window.expanded .welcome-message {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
} 

body.chat-expanded {
  overflow: hidden;
}

.live-data-button {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  margin-right: 8px;
  cursor: pointer;
  font-size: 12px;
}

.location-selector {
  position: absolute;
  top: 50px;
  right: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
}

.location-selector-content {
  min-width: 250px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.location-selector h4 {
  margin: 0 0 10px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  color: #333;
  font-size: 16px;
}

.location-selector .chat-location-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.location-selector .chat-location-select:focus {
  outline: none;
  border-color: #E26313;
  box-shadow: 0 0 0 2px rgba(226, 99, 19, 0.2);
}

.location-option {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
}

.location-option:hover {
  background-color: #f5f5f5;
}

.location-option.active {
  background-color: #E26313;
  color: white;
}

.selected-locations {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-location-tag {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.remove-location {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.remove-location:hover {
  background-color: rgba(0,0,0,0.1);
  color: #333;
}

.add-market-button {
  background: none;
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 8px;
  color: #666;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 14px;
  transition: all 0.2s ease;
}

.add-market-button:hover {
  background-color: #f5f5f5;
  border-color: #999;
  color: #333;
}

.location-selector-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.apply-button {
  background-color: #E26313;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.apply-button:hover {
  background-color: #d15a0f;
}

.location-inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.zip-input-container {
  display: flex;
  gap: 8px;
}

.zip-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.zip-add-button {
  padding: 8px 16px;
  background-color: #E26313;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.zip-add-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-zip-button {
  width: 100%;
  padding: 8px;
  background: none;
  border: 1px dashed #ccc;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.add-zip-button:hover {
  background-color: #f5f5f5;
  border-color: #999;
  color: #333;
}

.zip-tag {
  background-color: #f0f7ff;
  border-color: #cce5ff;
}

.location-selector-content {
  min-width: 300px;
}

.selected-locations {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-location-tag {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.remove-location {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.remove-location:hover {
  background-color: rgba(0,0,0,0.1);
  color: #333;
}

.location-selector {
  position: absolute;
  top: 50px;
  right: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
}

.location-selector-content {
  min-width: 250px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.location-selector h4 {
  margin: 0 0 10px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  color: #333;
  font-size: 16px;
}

.location-selector .chat-location-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.location-selector .chat-location-select:focus {
  outline: none;
  border-color: #E26313;
  box-shadow: 0 0 0 2px rgba(226, 99, 19, 0.2);
}

.location-selector-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.apply-button {
  background-color: #E26313;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.apply-button:hover {
  background-color: #d15a0f;
}

.selected-location-tag.zip-tag {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}