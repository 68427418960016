/* src/components/ZipCodeMap.css */

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.content {
    min-height: 120vh; /* This forces scrollable area below the content */
}

.zip-description-wrapper {
    width: 100%;
}

.zip-content-wrapper {
    display: flex;
    flex-direction: row;
    height: 82vh;
    width: 100%;
    gap: 20px;
}

.map-container {
    width: 35%;
    min-width: 350px;
    position: relative;
    overflow: hidden;
    flex: 0 0 35%;
    height: 100% !important;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.mapboxgl-map {
    width: 100% !important;
    height: 100% !important;
}

.mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
}

.table-container {
    width: 65%;
    flex: 0 0 65%;
    padding: 20px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 1.2em;
    z-index: 1;
}

.overlay.error {
    background-color: rgba(255, 0, 0, 0.3);
    color: red;
}

.legend {
    position: absolute;
    bottom: 20px;
    left: 10px;
    background: white;
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    z-index: 1;
}

.legend-gradient {
    width: 20px;
    height: 40vh;
    background: linear-gradient(to bottom, #225ea8, #1d91c0, #41b6c4, #7fcdbb, #c7e9b4, #ffffcc);
}

.legend-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    margin-left: 10px;
}

.legend-labels span {
    font-size: 12px;
    text-align: left;
}

.county-info {
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.county-info table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.county-info th, .county-info td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.county-info th {
    background-color: #f2f2f2;
}

@media (max-width: 1040px) {
    .zip-content-wrapper {
        flex-direction: column;
        height: auto;
    }

    .map-container,
    .table-container {
        width: 100%;
        max-width: 100%;
        flex: none;
        margin: 0;
    }

    .map-container {
        height: 60vh !important;
        min-height: 400px;
    }
}

@media (min-width: 1400px) {
    .table-container {
        width: 55%;
        flex: 0 0 55%;
    }

    .map-container {
        width: 45%;
        flex: 0 0 45%;
    }
}

.chart-sections {
    margin-top: 20px;
}

.chart-container {
    border: 1px solid #ccc;
    background-color: #f4f2f2;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container canvas {
    width: 100% !important;
    height: auto !important;
}

.chart-error {
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chart-error button {
    margin-top: 10px;
    padding: 5px 10px;
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
  