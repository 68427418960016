/* ComponentDescription.css */

.component-description-container {
  background: #f3f4f6;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 0 150px 20px 150px; /* Match your original margins */
}

.component-description-container:hover {
  background: #f8f9fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.description-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-right: 30px; /* Space for the arrow */
}

.description-content {
  flex: 1;
  padding: 0 20px;
}

.description-content p {
  color: #374151;
  line-height: 1.6;
  margin: 0 0 8px 0;
  font-size: 1.05rem;
}

.see-more-info {
  font-style: italic;
  color: #6b7280;
  font-size: 0.95rem !important;
  margin-top: 8px !important;
}

.dropdown-arrow {
  color: #333333;
  font-size: 14px;
  transition: transform 0.3s ease;
  align-self: center;
}

.dropdown-arrow.expanded {
  transform: rotate(180deg);
}

.description-details {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e5e7eb;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.features-list {
  margin: 12px 0 0 0;
  padding-left: 20px;
  padding-right: 20px;
  list-style-type: none;
  max-width: 1200px;
  text-align: left;
}

.features-list li {
  color: #374151;
  line-height: 1.6;
  margin: 8px 0;
  font-size: 1.05rem;
  position: relative;
  display: block;
  padding-left: 20px;
  text-align: left;
}

.features-list li::before {
  content: "•";
  color: #333333;
  font-weight: bold;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
} 