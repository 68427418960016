/* src/components/Home.css */
html, body {
  overscroll-behavior: none;
}

/* Auth Message Container */
.auth-message-container {
  text-align: center;
  background-color: #f9f9f9; /* Light background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.auth-message-container p {
  font-size: 1.2rem;
  color: #333; /* Darker text for better readability */
  margin-bottom: 15px;
}

.auth-message-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.auth-message-container ul li {
  display: inline;
}

.auth-message-container ul li a {
  text-decoration: none;
  color: #82a6d1; /* Link color similar to App-link */
  font-weight: bold;
  border: 2px solid #82a6d1;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.auth-message-container ul li a:hover,
.auth-message-container ul li a:active {
  background-color: #82a6d1; /* Hover and Active effect */
  color: white;
}

/* Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Start content at the top */
  text-align: center;
  width: 100%;
  height: auto; /* Ensure container grows with content */
  max-width: 100%;
  margin: 0 auto;
  padding: 0px;
  overflow: visible; /* Remove overflow restrictions */
  min-height: 100vh;
}


/* Listings Charts Container */
.listings-charts-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between rows */
}

.listings-chart-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between charts */
  justify-content: space-between; /* Distribute space evenly */
}

.listings-chart-wrapper {
  flex: 1 1 calc(50% - 20px); /* Flex items take up half the container */
  min-width: 300px; /* Minimum width for each chart */
  box-sizing: border-box;
}

.listings-heatmap-table {
  width: 100%; /* Full width of the wrapper */
  max-width: 500px; /* Maximum width for each table */
  overflow: auto; /* Enable scrolling if content overflows */
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .listings-chart-wrapper {
    flex: 1 1 calc(100% - 20px); /* Full width on smaller screens */
  }
}

.chart-wrapper {
  flex: 1 1 1200px;
  max-width: 1800px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
}

.chart-wrapper h2 {
  margin-bottom: 10px; /* Space between title and chart */
  font-size: 1.2rem;
}

.chart-wrapper canvas {
  width: 100% !important; /* Ensure canvas uses the full width of the container */
  height: 100% !important; /* Ensure canvas uses the full height of the container */
}

@media (max-width: 1800px) {
  .chart-wrapper {
    max-width: 95vw;
  }
}

@media (max-width: 1200px) {
  .chart-wrapper {
    max-width: 95vw;
  }
  .chart-wrapper canvas {
    height: 400px !important; /* Reduce height for smaller screens */
  }
}

@media (max-width: 900px) {
  .chart-wrapper {
    max-width: 100%; /* Full width on smaller screens */
  }
  .chart-wrapper canvas {
    height: 400px !important; /* Reduce height for smaller screens */
  }
}

@media (max-width: 600px) {
  .chart-wrapper {
    max-width: 100%; /* Full width on smallest screens */
  }
  .chart-wrapper canvas {
    height: 300px !important; /* Further reduce height for smallest screens */
  }
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
  color: black;
}

/* Product Type Buttons */
.product-type-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.listing-type-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.property-type-container {
  display: flex;
  justify-content: center;
  gap: 70px; /* Increased gap for more padding between property types */
}

.property-type-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Space between each property type within listing type */
}

.bedroom-buttons {
  display: flex;
  flex-direction: row; /* Changed to row for horizontal layout */
  gap: 10px; /* Space between buttons */
}

.product-type-button {
  background-color: #009999;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.product-type-button:hover {
  background-color: #009999;
}

.product-type-button.active {
  background-color: #009999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Section Buttons */
.section-buttons button {
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.section-buttons .active {
  background-color: #009999;
  color: white;
  border-color: #009999;
}

/* General Heading and Paragraph */
h1, p {
  display: block; /* Ensures they stack on top of each other */
}

/* Responsive Container */
@media (min-width: 1200px) {
  .container {
    max-width: 1800px !important;
    width: 95% !important;
  }
}


.content {
  padding: 20px;
  padding-top:0px;
  margin-top: 0px;
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  flex-wrap: wrap;
  overflow: visible;
  height: auto;
  max-height: none;
  min-height: 110vh; /* Forces content to be taller than viewport */
  padding-bottom: 80px; /* Adds padding at the bottom */
}

.auth-button{
  margin-left: 10px;
  padding: 5px;
  flex-wrap: wrap;
}

@media print {
  /* Hide the section buttons when printing */
  .section-buttons {
    display: none !important;
  }

  /* Hide any loading messages */
  .loading-message {
    display: none !important;
  }

  /* Ensure content starts from the top */
  .content {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  /* Remove any unnecessary spacing */
  .home-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}