.supply-demand-charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.supply-demand-chart-wrapper {
  width: 55%;
  min-width: 450px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 10px auto;
  aspect-ratio: 16/9;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  gap: 20px;
}

.loading-container p {
  color: #666;
  margin-top: 10px;
}

/* Responsive breakpoints */
@media (max-width: 1000px) {
  .supply-demand-chart-wrapper {
    width: 90%;
    min-width: 350px;
  }
}

@media (max-width: 700px) {
  .supply-demand-chart-wrapper {
    aspect-ratio: 4/3;
  }
}

@media (max-width: 500px) {
  .supply-demand-chart-wrapper {
    width: 95%;
    min-width: 300px;
    padding: 10px;
    aspect-ratio: 1/1;
  }
}
