/* src/components/MacroEconMap.css */

/* New container structure with added horizontal margins */
.macro-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding-left: 40px;  /* Add left margin */
  padding-right: 40px; /* Add right margin */
  box-sizing: border-box;
}

.macro-description-wrapper {
  width: 100%;
}

.macro-content-wrapper {
  display: flex;
  flex-direction: row;
  height: 82vh;
  width: 100%;
  gap: 20px;
  margin-bottom: 0px;
}

/* Map container - match ZipCodeMap styling */
.map-container {
  width: 35%;
  min-width: 350px;
  position: relative;
  overflow: hidden;
  flex: 0 0 35%;
  height: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Make selectors specific to MacroEconMap */
.map-container .mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}

.map-container .mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

/* Table container - match ZipCodeMap styling */
.table-container {
  width: 65%;
  flex: 0 0 65%;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

/* Overlay for loading and errors */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1.2em;
  z-index: 1;
}

.overlay.error {
  background-color: rgba(255, 0, 0, 0.3);
  color: red;
}

/* Legend */
.legend {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: white;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1;
}

.legend-gradient {
  width: 20px;
  height: 40vh;
  background: linear-gradient(to bottom, #225ea8, #1d91c0, #41b6c4, #7fcdbb, #c7e9b4, #ffffcc);
}

.legend-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40vh;
  margin-left: 10px;
}

.legend-labels span {
  font-size: 12px;
  text-align: left;
}

/* County info */
.county-info {
  width: 100%;
  max-width: 100%;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.county-info table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.county-info th, .county-info td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.county-info th {
  background-color: #f2f2f2;
}

/* Chart sections */
.chart-sections {
  margin-top: 20px;
  width: 100%;
  padding: 0 10px;
}

/* Aggregation Notice */
.aggregation-chart-notice {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0 20px;
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 1.5;
}

.aggregation-chart-notice h4 {
  color: #495057;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
}

.aggregation-chart-notice ul {
  padding-left: 20px;
  margin-bottom: 10px;
}

.aggregation-chart-notice li {
  margin-bottom: 5px;
}

.aggregation-chart-notice strong {
  color: #0d6efd;
}

.chart-container {
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f4f2f2;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container canvas {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}

/* Responsive design - match ZipCodeMap */
@media (max-width: 1040px) {
  .macro-content-wrapper {
    flex-direction: column;
    height: auto;
  }

  .map-container,
  .table-container {
    width: 100%;
    max-width: 100%;
    flex: none;
    margin: 0;
  }

  .map-container {
    height: 60vh !important;
    min-height: 400px;
  }
}

@media (min-width: 1400px) {
  .table-container {
    width: 55%;
    flex: 0 0 55%;
  }

  .map-container {
    width: 45%;
    flex: 0 0 45%;
  }
}

/* Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Example: Ensure marker popups have appropriate styles */
.mapboxgl-popup {
  pointer-events: none; /* Allows underlying map interactions */
}

.selected-marker {
  background-image: url('https://docs.mapbox.com/demos/custom-markers-gl-js/mapbox-icon.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.marker-popup {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.marker-popup .mapboxgl-popup-content {
  padding: 10px;
  border-radius: 4px;
}

.popup-content {
  padding: 8px;
  font-weight: bold;
  text-align: center;
}

