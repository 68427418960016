.local-demographics-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.demographics-description-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.demographics-content-wrapper {
  display: flex;
  flex-direction: row;
  height: 78vh;
  width: 100%;
  gap: 20px;
}

.demographics-map-container {
  width: 35%;
  min-width: 350px;
  position: relative;
  overflow: hidden;
  flex: 0 0 35%;
  height: 100% !important;
  border: 1px solid #ccc;
}

.charts-section {
  width: 65%;
  flex: 0 0 65%;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.charts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.chart-wrapper {
  width: 100%;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 600px;
  margin-bottom: 20px;
}

.chart-wrapper canvas {
  width: 100% !important;
  height: 100% !important;
  max-height: 550px !important;
}

/* Ensure Mapbox elements are properly sized */
.mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}

.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Update responsive design to match other components */
@media (max-width: 1040px) {
  .local-demographics-container {
    flex-direction: column;
    height: auto;
    padding: 0 20px;
  }

  .demographics-map-container,
  .charts-section {
    width: 100%;
    max-width: 100%;
    flex: none;
    margin: 0;
  }

  .demographics-map-container {
    height: 60vh !important;
    min-height: 400px;
  }
}

@media (min-width: 1400px) {
  .charts-section {
    width: 55%;
    flex: 0 0 55%;
  }

  .demographics-map-container {
    width: 45%;
    flex: 0 0 45%;
  }
}

.drop-pin-button:hover {
  background-color: #c55711 !important;
} 

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loading-content p {
  margin-top: 10px;
  color: #666;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
} 
