.signup-container {
    max-width: 400px;
    margin: 50px auto 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white !important;
    height: 550px;
    overflow: hidden;
}
  
  .signup-form .form-group {
    margin-bottom: 15px;
  }
  
  .signup-form .form-control {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    background-color: #fff5eb !important;
  }
  
  .signup-form .btn {
    width: 100%;
  }
  
  .message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
  
  .btn-primary {
    background-color: #009999 !important; /* Teal */
    border-color: #009999 !important;
  }
  
  .btn-primary:hover {
    background-color: #008080 !important; /* Slightly darker teal for hover */
    border-color: #008080 !important;
  }
  
  a {
    color: #009999 !important; /* Teal */
  }
  
  a:hover {
    color: #008080 !important; /* Slightly darker teal */
  }
  
  /* Override autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fff5eb inset !important;
      -webkit-text-fill-color: #000000 !important;
  }
  
  .form-control {
      background-color: #fff5eb !important;
  }