.stats-highlight-wrapper {
  width: 100%;
  max-width: 1400px;
  margin: 40px auto;
}

.community-header {
  font-family: 'Inter', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #2D2D30, #919191);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.05em;
  line-height: 1.2;
}

.community-subtitle {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 1.1rem;
  color: #6b7280;
  margin-bottom: 1.5rem;
  letter-spacing: 0.02em;
  font-weight: 500;
}

.area-overview {
  background: #f3f4f6;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 20px auto;
  max-width: 1400px;
}

.area-overview:hover {
  transform: translateY(-5px);
  background: #f8f9fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.area-overview h2 {
  color: #6b7280;
  font-size: 1.1rem;
  margin: 0 0 16px 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.area-overview p {
  color: #374151;
  line-height: 1.6;
  margin: 1rem 0;
  font-size: 1rem;
}

.area-overview strong {
  color: #111827;
  font-weight: 600;
}

/* Add spacing between sections */
.area-overview p + h2 {
  margin-top: 20px;
}

/* Ensure proper text wrapping */
.area-overview {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section-title {
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #2D2D30;
  margin: 0 0 0.9rem 0;
  padding-bottom: 0;
  text-align: left;
  background: linear-gradient(45deg, #2D2D30, #919191);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.05em;
}

.area-overview p {
  margin: 0;
  font-size: 1.05rem;
}

.area-overview strong {
  color: #111827;
  font-weight: 600;
}

.stats-highlight-container {
  background: #ffffff;
  border-radius: 12px;
  padding: 1rem 1.6rem 1.4rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
  margin-bottom: 1.8rem;
}

.stats-highlight-container:last-child {
  margin-bottom: 0;
}

.stats-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
}

.charts-row {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 1rem;
}

.charts-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 0;
  max-width: 1100px;
}

.stats-highlight-wrapper .chart-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2D2D30;
  margin-bottom: 1rem;
  text-align: center;
}

.stats-highlight-wrapper .stats-chart {
  width: 100%;
  height: calc(100% - 3rem);
  position: relative;
}

.stats-highlight-wrapper .stats-chart > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.stats-highlight-wrapper .stats-chart canvas {
  width: 100% !important;
  height: 100% !important;
}

.stat-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.75rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: fit-content;
}

.stat-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.stat-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.stat-title {
  font-size: 1.15rem;
  color: #6b7280;
  margin: 0;
}

.stat-value-container {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 700;
  color: #111827;
}

.stat-change {
  font-size: 1rem;
  font-weight: 600;
  cursor: help;
  position: relative;
}

.stat-change.positive {
  color: #059669;
}

.stat-change.negative {
  color: #dc2626;
}

/* Custom tooltip styles */
.stat-change[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: normal;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 8px;
}

.stat-change[title]:hover::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  margin-bottom: -4px;
}

.stat-period {
  font-size: 0.875rem;
  color: #6b7280;
}

.stat-value-sub {
  font-size: 1.25rem;
  font-weight: 600;
  color: #6b7280;
  margin-left: 0.5rem;
}

.permits-section {
  margin-top: 3rem;
}

.permits-table-section {
  margin-top: 2.5rem;
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
}

.table-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2D2D30;
  margin-bottom: 1.5rem;
  text-align: left;
}

.permits-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Inter', sans-serif;
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.permits-table th,
.permits-table td {
  padding: 1rem;
  text-align: right;
  border-bottom: 1px solid #e5e7eb;
}

.permits-table th:first-child,
.permits-table td:first-child {
  text-align: left;
}

.permits-table th {
  background: #f3f4f6;
  font-weight: 600;
  color: #4b5563;
}

.permits-table tr:last-child td {
  border-bottom: none;
}

.permits-table tbody tr:hover {
  background: #ffffff;
}

.permits-content-row {
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.permits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 400px;
  flex-shrink: 0;
}

.stats-chart-container.permits-chart {
  padding: 1rem;
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  /* height: 260px !important; */
}

.stats-chart-container.permits-chart:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.permits-chart {
  min-width: 0;
  height: 100%;
}

.permits-chart .chart-title {
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
  .area-overview {
    margin: 20px;
  }

  .charts-row {
    flex-direction: column;
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .stats-highlight-wrapper .stats-chart-container {
    width: 100%;
  }

  .permits-content-row {
    flex-direction: column;
  }

  .permits-grid {
    width: 100%;
  }

  .permits-chart {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .community-header {
    font-size: 2rem;
  }

  .permits-grid {
    grid-template-columns: 1fr;
  }
}

.listings-content-row {
  display: flex;
  gap: 1.1rem;
  width: 100%;
  align-items: flex-start;
}

.listings-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  width: 400px;
  flex-shrink: 0;
}

/* Specific styles for stat cards in the listings section */
.listings-stats .stat-card {
  padding: 1.75rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listings-stats .stat-content {
  gap: 0.75rem;
  text-align: center;
  width: 100%;
}

.listings-stats .stat-title {
  font-size: 1.15rem;
  line-height: 1.2;
  text-align: center;
}

.listings-stats .stat-value-container {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  justify-content: center;
}

.listings-stats .stat-value {
  font-size: 1.75rem;
  line-height: 1.2;
  text-align: center;
}

.listings-stats .stat-change {
  font-size: 1rem;
  line-height: 1.2;
}

.listings-stats .stat-period {
  font-size: 0.875rem;
  line-height: 1.2;
  text-align: center;
}

/* Adjust chart sizes in the listings section */
.listings-content-row .stats-chart-container {
  padding: 1rem;
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.listings-content-row .stats-chart-container:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.listings-content-row .stats-chart-container:first-child {
  height: 400px !important;
}

.listings-content-row .stats-chart-container:last-child {
  height: 425px !important;
}

.listings-content-row .chart-title {
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

.listings-content-row .stats-chart {
  flex: 1;
  min-height: 0;
  position: relative;
}

.listings-content-row .stats-chart > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 1200px) {
  .listings-content-row {
    flex-direction: column;
    gap: 1rem;
  }

  .listings-stats {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .charts-column {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .listings-stats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .listings-stats {
    grid-template-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

/* Add these specific styles for the permits section */
.permits-grid .stat-card {
  padding: 1.75rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.permits-grid .stat-content {
  gap: 0.75rem;
  text-align: center;
  width: 100%;
}

.permits-grid .stat-title {
  font-size: 1.15rem;
  line-height: 1.2;
  text-align: center;
}

.permits-grid .stat-value-container {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  justify-content: center;
}

.permits-grid .stat-value {
  font-size: 1.75rem;
  line-height: 1.2;
  text-align: center;
}

.permits-grid .stat-period {
  font-size: 0.875rem;
  line-height: 1.2;
  text-align: center;
} 