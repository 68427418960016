/* Chart Container */
.overview-container {
  flex: 1;
  min-width: 0;
  max-width: 100%;
  background: rgb(246, 246, 246);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 10px;
  text-align: left;
  overflow: hidden;
}

/* Styled Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 16px;
  background-color: #ffffff; /* Bright background */
  margin-top: 15px;
  border-radius: 8px; /* Match the container's rounded corners */
  overflow: hidden; /* Ensure borders look clean */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.styled-table th,
.styled-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.styled-table th {
  background-color: #fd9245;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  font-size: 17px;
}

.styled-table tr:nth-child(even) {
  background-color: #fafafa; /* Light row shading */
}

.styled-table tr:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
}

.styled-table td {
  color: #555;
  font-size: 16px;
}

.overview-content {
  display: flex;
  gap: 2rem; /* Adds space between the table and the chart */
  align-items: flex-start; /* Aligns items at the start of the container */
}

.permit-table {
  flex: 1; /* Allows the table to take up available space */
}

.permit-chart {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* Add this new style for the chart container */
.permit-chart > div {
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;
  max-height: 100%;
}

/* Add this to ensure charts are responsive */
.permit-chart canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.overview-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.charts-row {
  display: flex;
  gap: 2rem;
  width: 100%;
}

/* Optional: Make charts stack on smaller screens */
@media (max-width: 1200px) {
  .charts-row {
    flex-direction: column;
  }
}

/* Adjust table font sizes for medium screens */
@media (min-width: 1200px) and (max-width: 1400px) {
  .styled-table {
    font-size: 14px;  /* Down from 16px */
  }

  .styled-table th {
    font-size: 15px;  /* Down from 17px */
  }

  .styled-table td {
    font-size: 14px;  /* Down from 16px */
  }
}

/* Even smaller font sizes for 1200-1300px */
@media (min-width: 1200px) and (max-width: 1300px) {
  .styled-table {
    font-size: 13px;  /* Down from 14px */
  }

  .styled-table th {
    font-size: 14px;  /* Down from 15px */
  }

  .styled-table td {
    font-size: 13px;  /* Down from 14px */
  }
}

/* Smallest font sizes for 1200-1250px */
@media (min-width: 1200px) and (max-width: 1250px) {
  .styled-table {
    font-size: 12px;  /* Down from 13px */
  }

  .styled-table th {
    font-size: 13px;  /* Down from 14px */
  }

  .styled-table td {
    font-size: 12px;  /* Down from 13px */
  }
}

/* Add this to your existing CSS */

/* For clickable cells, show a down arrow */
.clickable-cell {
    cursor: pointer;
    position: relative;
    text-decoration: underline;
    color: #009999;
    /* Add some padding to the right so arrow does not overlap text */
    padding-right: 15px;
  }
  
  /* Add a down arrow after the cell content */
  .clickable-cell::after {
    content: ' ▼';
    font-size: 1em;
    color: rgb(0, 0, 0);
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Highlight the currently active cell */
  .active-cell {
    background-color: #e7e7e7; /* Light blue highlight */
    font-weight: bold;
  }
  
.loading-indicator {
  font-size: 0.9em;
  color: #666;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin: 10px 0;
}

.overview-container > div {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.overview-container > div:not(.loading-indicator) {
  opacity: 1;
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto 30px auto;
  padding: 0 20px;
}

.overview-card {
  background: #f3f4f6;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.overview-card:hover {
  transform: translateY(-5px);
  background: #f8f9fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.overview-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overview-title {
  color: #6b7280;
  font-size: 1.1rem;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.overview-text {
  color: #111827;
  font-size: 1rem;
  line-height: 1.5;
}

.overview-text strong {
  color: #111827;
  font-weight: 600;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .overview-grid {
    grid-template-columns: 1fr;
  }
}

.overview-card.full-width {
  grid-column: 1 / -1;
}

.detailed-analysis {
  color: #374151;
}

.detailed-analysis h4 {
  color: #111827;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 1.5rem 0 1rem 0;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 0.5rem;
}

.detailed-analysis h4:first-of-type {
  margin-top: 1rem;
}

.detailed-analysis p {
  margin: 1rem 0;
  line-height: 1.6;
}

.detailed-analysis ul {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  list-style-type: none;
}

.detailed-analysis ul li {
  margin: 0.5rem 0;
  position: relative;
}

.detailed-analysis ul li::before {
  content: "•";
  color: #6b7280;
  font-weight: bold;
  position: absolute;
  left: -1.1rem;
}

.detailed-analysis ul ul {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.detailed-analysis ul ul li::before {
  content: "◦";
}
  