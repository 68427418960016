/* src/components/Navbar.css */

/* Base navbar styling (optionally remove background-color if using Bootstrap .bg-* classes) */
.navbar {
    padding: 8px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-brand {
    min-width: 120px;
    display: flex;
    align-items: center;
  }
  
  .navbar-brand img {
    height: 45px;
    width: auto;
    object-fit: contain;
  }
  
  /* Left section with location & distance selectors */
  .navbar-left {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .selectors {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .location-select,
  .distance-select {
    font-size: 15px;
    padding: 6px 12px;
    height: 38px;
    border-radius: 4px;
  }
  
  .location-select {
    width: 200px;
  }
  
  .distance-select {
    width: 100px;
  }
  
  /* Nav links and client select */
  .navbar-nav {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Spacing between nav items */
  .navbar-nav .nav-item {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
  
  .client-select-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .client-select {
    height: 38px;
    padding: 6px 12px;
    font-size: 15px;
    border-radius: 4px;
  }
  
  .nav-links-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .filters-button-container button {
    height: 38px;
    padding: 0 18px;
    font-size: 15px;
  }
  
  /* -----------------------------------------------------
     BOOTSTRAP COLLAPSE BEHAVIOR & RESPONSIVE STYLES
     -----------------------------------------------------
     By default, .navbar-expand-lg:
       - Shows the toggler (hamburger) below 992px
       - Expands (shows horizontal menu) at 992px and above
  ------------------------------------------------------ */
  
  /* Remove the toggler above the 992px breakpoint (Bootstrap does this automatically) */
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  
  /* Below 992px, the toggler is visible and the .collapse 
     container (navbar-collapse) is hidden by default. 
     When toggled, .show is added (handled in your React code). */
  
  /* Style the collapsed menu (shown after toggler click) on small screens */
  @media (max-width: 991.98px) {
    .navbar-collapse {
      position: absolute;
      top: 70px; /* matches navbar height */
      left: 0;
      right: 0;
      background-color: #f8f9fa;
      padding: 15px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border-top: 1px solid #eee;
    }
  
    .navbar-collapse.show {
      display: block;
    }
  
    .navbar-collapse .navbar-nav {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  
    /* Make each nav link/button full-width in the collapse */
    .navbar-collapse .nav-link,
    .navbar-collapse .btn-link,
    .navbar-collapse .filters-button-container button {
      display: block;
      width: 100%;
      padding: 12px 20px;
      text-align: left;
      background-color: white;
      border: 1px solid #dee2e6;
      border-radius: 6px;
      color: #333;
      font-size: 15px;
      transition: all 0.2s ease;
      text-decoration: none;
      margin: 0;
    }
  
    /* Example: If you want the distance select to show up only in collapsed mode */
    .distance-select {
      display: none;
    }
  
    .navbar-collapse .distance-select {
      display: block;
      width: 100%;
      margin: 5px 0;
    }
  
    /* Hide client select by default on smaller screens */
    .client-select-container {
      display: none;
    }
  
    .navbar-collapse.show .client-select-container {
      display: flex;
      width: 100%;
    }
  
    /* Similarly hide filters-button-container if you want them in the collapse */
    .filters-button-container {
      display: none;
    }
  
    .navbar-collapse.show .filters-button-container {
      display: block;
      width: 100%;
    }
  
    /* If .nav-links-container needs to collapse too */
    .nav-links-container {
      display: none;
    }
  
    .navbar-collapse.show .nav-links-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  
  /* Below ~768px: minor tweaks to inputs, spacing, etc. */
  @media (max-width: 768px) {
    .navbar-left {
      flex-direction: row;
      align-items: center;
    }
  
    .location-select {
      width: 180px; /* slightly smaller on mobile */
    }
  }
  
  /* NAV LINK COLORS */
  .navbar-nav .nav-link {
    color: #fff; 
    font-weight: bold; 
    transition: color 0.2s; 
    font-size: 15px;
  }
  
  .navbar-nav .nav-link:hover {
    color: #007bff; /* Hover color */
  }
  
  /* Client select label color */
  .nav-item.d-flex label {
    margin-right: 5px;
    color: #fff;
  }
  
  /* Additional styling for the client-select dropdown */
  .client-select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 14px;
  }
  
  /* Fine-tune these for the collapsed layout */
  @media (max-width: 992px) {
    .selectors {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .distance-select {
      margin-left: 0;
      margin-top: 10px;
    }
  
    .nav-item.d-flex {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-item.d-flex label,
    .client-select {
      margin-right: 0;
      margin-top: 5px;
    }
  }
  
  /* PRINT STYLES - Hide navbar on print */
  @media print {
    nav,
    .navbar,
    .nav-container,
    header {
      display: none !important;
    }
    body {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    .charts-container {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
  
  /* Example overrides for link/button colors if desired */
  .nav-link {
    color: #797979 !important; /* Lighter grey */
  }
  
  .nav-link:hover {
    color: #555555 !important; /* Slightly darker grey on hover */
  }
  
  .navbar .btn-link {
    color: #797979 !important;
  }
  
  .navbar .btn-link:hover {
    color: #555555 !important;
  }
  